<template>
	<modal class="NoxModalPackageDetails" name="NoxModalPackageDetails" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title" v-html="$store.getters.getLanguageText('5.4', 0, { name: $store.state.noxSystemSettings['package_name_x' + noxPackage] })"></div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info" v-html="$store.getters.getLanguageText('5.4', (1 + noxPackage), { name: $store.state.noxSystemSettings['package_name_x' + noxPackage] })"></div>
				<div class="nox_modal_alert"></div>
				<div class="nox_modal_buttons">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	export default {
		data: () => ({
			noxPackage: 0
		}),
		methods: {
			beforeOpen: function(event) {
				this.noxPackage = event.params.package;
			},
			close: function() {
				this.$modal.hide('NoxModalPackageDetails');
			}
		}
	}
</script>
